/* 

.panel {
    background-color: #eeeeee;
    border-bottom: 1px solid #999999;
    border-right: 1px solid #999999;
    float: left;
    margin-top: 10px;
}

.bold {
    font-weight: bold;
    font-size: smaller;
}

.clear {
    clear: both;
}

.subpanel {
    float: left;
    padding: 5px;
}

.subpanel_title {
    font-weight: bold;
    font-size: larger;
}
.subpanel_content {
    border: 1px solid #999999;
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
}

.component {
    float: left;
    border-right: 1px solid #999999;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.last_component {
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    margin-top: 5px;
    margin-bottom: 5px;
}

.small_component {
    background-color: white;
    padding: 5px;
    border: 1px solid #999999;
    line-height: 25px;
    height: 25px;

}

div.hc:hover {
    background-color: #ccc;
}

#next {
    margin-left: 2px;
    vertical-align: middle;
    cursor: pointer;
}
#prev {
    margin-right: 2px;
    vertical-align: middle;
    cursor: pointer;
}

#top_panel {
    width: 100%;
    margin: 0px;
    background-color: #eeeeee;
    border-bottom: 1px solid #999999;
    border-right: 1px solid #999999;
}

#colors_panel {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #eeeeee;
    border-bottom: 1px solid #999999;
    border-right: 1px solid #999999;
    width: 200px;
    margin-top: 10px;
}
#diagram_panel {
    margin-top: 10px;
    display: table;
    background-color: #eeeeee;
    border-bottom: 1px solid #999999;
    border-right: 1px solid #999999;
}

#toggle_show_colors {
    font-size: 10px;
    font-weight: normal;
    margin-left: 10px;
    cursor: pointer;
}

#clear_colors {
    font-size: 10px;
    font-weight: normal;
    margin-left: 10px;
    cursor: pointer;
}

.hidden {
    display: none;
}

#knot_diagram {
    border: 1px solid #999999;
    background-color: white;
}

#error {
    color: red;
    font-weight: bold;
    line-height: 25px;
} */

ol.steps-list{
    width:100%;
    text-align:left;
    margin:0;
    padding:0;
    text-transform: uppercase;
    font-size:1rem;
}
ol { counter-reset: step; }
ol li { display: block;padding-bottom:0.25rem;font-family: serif; }
ol li:before {
            content: counter(step) ". ";
            counter-increment: step;
            font-size:0.75rem;
            color: #999;
            margin-left:-1rem;
            padding-right:0.25rem;
        }

        .colorgrid{
            display:grid;
            grid-template-columns: repeat(auto-fill, minmax(3rem, 1fr));
            grid-gap: 0.5rem;
            
        }
        button.download-pdf{
           
        }
        p.error{
            color:rgb(208, 101, 24);
            font-size:0.75rem;
        }