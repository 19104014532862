.legal-paper h1{font-size:1.1rem}
.legal-paper h2{font-size:1rem}
.legal-paper h3{font-size:0.9rem}
.legal-paper h4{font-size:0.8rem}
.legal-paper h5{font-size:0.7rem}
.legal-paper h6{font-size:0.6rem}
.legal-paper h1,.legal-paper h2,.legal-paper h3,.legal-paper h4,.legal-paper h5,.legal-paper h6{
    text-align: left;
}
.legal-paper p{font-size:0.8rem;text-align: left;}
.legal-paper ul{font-size:0.8rem;text-align: left;}
.legal-paper {
    position: relative;
    transform: rotate(-1deg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    text-align: left;
    background-color: #fff;
    border:1px solid #eee;
    border-radius: 5px;
    margin-bottom: 20px;
}

.legal-paper::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border:1px solid #eee;
    transform: rotate(2deg);
    z-index: -1;
}

.legal-paper::after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: rotate(-2deg);
    border:1px solid #eee;
    z-index: -1;
}


@media screen and (max-width: 720px) {
   
    .legal-paper{
        transform: rotate(-0.1deg) translateX(0.25rem);

    }
    .legal-paper::before {
        transform: rotate(0.1deg);
    }
    .legal-paper::after{
        transform: rotate(-0.1deg);
    } 
}