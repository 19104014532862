#admin,.admin-tabs{
    width:100%;
}
.admin-tabs{
    display:flex;
    background: #000;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom:20px;
}
.admin-tabs button:hover{
    background: #900;
}
.admin-tabs button.active{
    background: #900;
}
.newdraftButton{
    margin:5px;font-size:12px;
    margin-left:calc(100% - 7rem);
}
ul.article-list{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
h3{
    font-size:0.7rem;
    border-bottom:1px solid #ccc;
    text-transform: uppercase;
    margin-bottom:1rem;
    text-align: center;
}
ul.article-list li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}
ul.article-list li strong{
    width:100%;
}
ul.article-list li button{
    background: none;
    color: #000;
    border: 0;
    padding: 5px;
    width:2rem;
    height:2rem;
    margin-left:5px;
}

h2{
    font-size:1rem;
    text-transform: uppercase;
    margin-bottom:1rem;
    text-align: center;
}
.form{
    display:flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width:100%;
}
.form label{
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content:space-between;
    align-items: flex-end;
    flex-direction: row;
    margin-bottom:2rem;
    box-sizing: border-box;
}
.editor-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.editor-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
