/* src/components/Footer.css */
.footer {
    text-align: center;
    width: 100%;
    max-width:800px;
    margin:0 auto;
    font-size:0.75rem;
    font-family:sans-serif;
    padding: 20px;
    color:#999;
    box-sizing: border-box;
  }
  .footer a{
    color:#999;
    text-decoration: underline;
  }

  .creator{
    font-size:0.75rem;
    color:#666;
    margin-bottom:0.75rem;
  }
  .creator a{
    color:#666;
    text-decoration: underline;
  }