/* src/pages/Contact.css */
.main-content {
    padding: 40px;
    text-align: center;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width:100%;
  }
  
  .contact-form label {
    width: 100%;
    
    max-width: 400px;
   display: flex;
    justify-content:space-between;
    align-items: flex-end;
    flex-direction: row;
    margin-bottom:2rem;
    box-sizing: border-box;

    /* letter-spacing:0.1rem; */
  }

  .contact-form label:last-of-type {
    align-items: flex-start;
  }
  .contact-form label span{
    display:flex;
    width:6rem;
    font-size:0.75rem;
    text-transform: uppercase;
    padding-right:1rem;
  }
  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    width:100%;
    padding: 10px;
    margin-top: 5px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    border:0;
    box-sizing: border-box;

    border-bottom:1px solid #ccc;
  }
  .contact-form input:focus,
    .contact-form select:focus,
    .contact-form textarea:focus {
      outline: none;
      border-color:#000;
    }
    .contact-form textarea {
      height: 200px;
      border:1px solid #ccc;

    }
  
  .contact-form button {
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
  }
  

  @media screen and (max-width: 720px) {
    .main-content {
      padding: 20px;
    }
    .contact-form label {
      flex-direction: column;
      align-items: flex-start;
    }
    .contact-form label span{
      width:100%;
      padding-right:0;
      margin-bottom:0.5rem;
    }
    
  }