/* src/pages/Creations.css */
.main-content {
    padding: 40px;
    text-align: center;
  }
  
  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .item {
    width: 200px;
  }
  
  .item img {
    width: 100%;
    height: auto;
  }
  
  .item p {
    margin-top: 10px;
  }
  