#app {
  max-width:800px;
  margin:0 auto;
  min-height:100vh;
  background:url(./assets/bg.png) repeat;
}
button{
  cursor:pointer;
  background-color: #000;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  font-size: 18px;
  font-family: 'Libre Baskerville', serif;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
a{
  text-decoration:none;
  color: rgb(152, 0, 0);
}

h1{
  font-size:2rem;
  text-align: center;
  margin-bottom:2rem;
  padding-top:2rem;
  border-top:2px solid #000;
}
h1::before{
  content:'';
  transform:rotate(45deg);  
  display:inline-block;
  opacity:0.3;
  
  width:0.5rem;
  height:0.5rem;
  margin-bottom:0.5rem;
  margin-right:1.5rem;
  background:#000;
  opacity: 0.3;

  
}
h1::after{
  content:'';
  transform:rotate(45deg);  
  display:inline-block;
  opacity:0.3;
  
  width:0.5rem;
  height:0.5rem;
  margin-bottom:0.5rem;
  margin-left:1.5rem;
  background:#000;
  opacity:0.3;

  
}

.main-content{
  min-height:100vh;
}
@media screen and (max-width: 720px) {
  h1{
    font-size:1.5rem;
  }
  }
/* for large screens where we see more than 800 we add side borders */
@media (min-width: 800px) {
  body{
    background:#fafafa;
    
  }
  #app {
    background: #fff;
    border: 3px solid transparent; /* Main border width and transparent base */
    border-image: repeating-linear-gradient(
      70deg, /* Angle for the diagonal pattern */
      #ccc, /* Rope color 1 */
      #eee 2px,
      #dedede 3px,
      #ddd 5px,
      #aaa 6px
    );
    border-image-slice: 1;
    border-top: 0;
    border-bottom: 0;
    z-index:10;
    position: relative;
    box-shadow: 0 0 0 1px #aaa, inset 0 0 0 1px #aaa;
    overflow-x:hidden;
  }
 
  
 
}
