.whoami{
   display:flex;
    justify-content:space-between;
    align-items:center;
    padding:4px;
    flex-direction: row;
    font-size:0.75rem;
    color: #666;

}
.whoami .avatar{
    width: 1.5rem;
    height: 1.5rem;
    max-width: 1.5rem;
    max-height:1.5rem;
    background:#ccc;
    border-radius: 50%;
    margin-right: 1rem;
}
.hidden{
    display:none;
}
.whoami .login-button{
    background: none;
    border: 0;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    cursor: pointer;
    transition: all 0.3s;
    filter: grayscale(100%) contrast(0%);
}
.whoami .logout-button{
    background: none;
    border: 0;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    cursor: pointer;
    transition: all 0.3s;
    filter: grayscale(100%) contrast(0%);
}

.whoami .login-button:hover,
.whoami .logout-button:hover{
    transform: scale(1.2);
}