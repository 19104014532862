/* src/components/Header.css */
.header {
    text-align: center;
    padding: 0;
    width:calc(100% - 1rem);
    margin:0 auto;
  }
  
  .logo {
    width: 100%;
    margin-bottom: 20px;
    cursor:pointer;
  }
  nav{
    padding:0;margin:0;
  }
  nav ul {
    
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin:0;
  }
  
  nav a {
    text-decoration: none;
    color: black;
    font-size: 1.25rem;
    padding:0 1rem;
    text-transform: uppercase;
  }
  nav a:hover{
    color: rgb(152, 0, 0);
  }
  nav li:after{
    content:'❖';
    font-size:1.25rem;
    opacity:0.3;
  }
  nav li:last-child:after{
    content:'';
  }

  .whoami{
    position:absolute;
    top:0;
    right:0;
  }

  @media screen and (max-width: 720px) {
    .header {
      padding: 0;
      width:100%;
      margin:0;
      
    }
    .logo{
        margin-bottom:0;
        
    }
    h1{
        margin-top:0;
    }
    nav li:after,nav li:before{
        font-size:0.66rem;
    }
    /* nav ul {
     
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(rem, 1fr));
        grid-gap: 1rem;
    }
    nav li:after,nav li:before,nav li:last-child:after{
        content:'❖';
        opacity:0.3;
        font-size:0.75rem;
        line-height:1rem;
      } */
    
    nav a {
      font-size: 0.75rem;
      padding:0 0.25rem;
    }
    .logo {
      width: 100%;
    }
    
  }