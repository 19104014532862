/* src/pages/Ateliers.css */
.main-content {
    padding: 40px;
    text-align: center;
    
  }
  
  .main-content h1 {
    margin-bottom: 20px;
  }
  
  .main-content p {
    margin-bottom: 30px;
    line-height: 1.6;
  }
  .main-content img{
    max-width:100%;
    max-height:100%;
  }