.newsletter-container {
  /* display: flex; */
  display:none;
  justify-content: center;

  align-items: center;
  background-color:#fff;
  padding: 2rem;
  border:3px double #000;
  margin-bottom:2rem;
  transition: all 1s;
  opacity:0;
  height:0;
  transform:scale(0) translateY(-100%);
  transition: all .2s;
}
.newsletter-container.loaded{
    opacity:1;
    height:100%;
    transform:translateY(0) scale(1);
}
.newsletter-container p{
    font-size:1.1rem;
    margin-right:1rem;
}