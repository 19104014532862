@import url('https://fonts.googleapis.com/css2?family=Schoolbell&family=Special+Elite&display=swap');

.calculator{
    text-align: left;
    margin:1rem;
    padding:2rem;
    box-sizing: border-box;
    width:calc(100% - 2rem);
    border: 1px dashed #000;
    
}
.calculator label,.calculator p{
    font-family: 'Special Elite', cursive;
    font-size: 1rem;
    margin-bottom: 1rem;
    letter-spacing: 0.033rem;
}
.calculator label{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size:0.875rem;
}

.calculator input,
.calculator select{
   color:blue;
   font-family: 'Schoolbell', cursive;
   font-size:1rem;
   border:0;
   border-bottom:1px solid #000;
   background: none;
   
}
.calculator input[type="number"]{
    width: 50px;
    text-align: right;
    
}
.calculator input[type="range"]{
    width: 120px;
}

.calculator button{
    background: #000;
    color: #fff;
    border: 0;
    padding: 10px 20px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    cursor: pointer;
    margin-top:1rem;
    
}
.calculator button:hover{
    background: #900;
}

.calculator h2{
    font-size:1rem;
    text-transform: uppercase;
    margin-bottom:1rem;
}
/*********** Baseline, reset styles ***********/
.calculator input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 120px;
  }
  
  /* Removes default focus */
  .calculator  input[type="range"]:focus {
    outline: none;
  }
  
  /******** Chrome, Safari, Opera and Edge Chromium styles ********/
  /* slider track */
  .calculator  input[type="range"]::-webkit-slider-runnable-track {
    background-color: #000000;
    border-radius: 0;
    height: 0px;
  }
  
  /* slider thumb */
  .calculator  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -5.2px; /* Centers thumb on the track */
    background-color: #0000ff;
    border-radius: 0rem;
    height: .75rem;
    width: .75rem;
    transform:rotate(45deg);

  }
  
  .calculator  input[type="range"]:focus::-webkit-slider-thumb {
    /* outline: 3px solid #0000ff;
    outline-offset: 0; */
  }
  
  /*********** Firefox styles ***********/
  /* slider track */
  .calculator  input[type="range"]::-moz-range-track {
    background-color: #000000;
    border-radius: 0;
    height: 0px;
  }
  
  /* slider thumb */
  .calculator  input[type="range"]::-moz-range-thumb {
    background-color: #0000ff;
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0rem;
    height: .75rem;
    width: .75rem;
    transform:rotate(45deg);
  }
  
  .calculator input[type="range"]:focus::-moz-range-thumb{
    /* outline: 3px solid #0000ff;
    outline-offset: 0.125rem; */
  }
  .calculator canvas{
    width:100%;
  }

  .calculator input[type="color"]{
    border:0;
    width: 1.5rem;
    height: 1.5rem;
    padding:0;


  }

  .calculator .result{
    border: 1px solid #000;
    margin-top:1rem;
    padding:1rem 2rem;

  }
  .calculator .stepnav{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:2rem;
    margin-top:2rem;
  }
  .calculator{
    position:relative;
  }

  .calculator button.stepper{
   
    background:none;
    color:#000;
    font-size:2rem;
    padding:0;
    margin:0;
   
  }
  .calculator button.stepper[disabled]{
    color:#ccc;
  }
  .calculator .current-step{
    font-size:1rem;
    
   
  }
  .calculator strong{
    font-size:inherit;
    padding-left:1rem;
    font-family: 'Schoolbell', cursive;
    color: #00f;
  }

  .calculator button.expander{
    background:none;
    color:#000;
    font-size:0.75rem;
    position: absolute ;
    bottom:0;
    right:0;
    color:#900;
  }

  .calculator.expanded button.expander{
    top: 0;
    right: 0;
    bottom:auto;
    margin:0;
  }

  .calculator em{
    font-size:0.75rem;
    
  }
  math{
    font-family: 'monospace';
    font-size:0.75rem;
    color:#999;
  }